import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import qs from 'qs'
import { DateTime } from 'luxon'
import moment from 'moment'

import Checkbox from '../../components/Checkbox'
import AppointmentSettings from './AppointmentSettings'
import AvalTime from './AvalTime'
import SearchJob from './SearchJob'
import ClientBlock from './ClientBlock'
import JobBlock from './JobBlock'
import AppointmentBlock from './AppointmentBlock'
import ZipCode from './ZipCode'
import AddressBlock from './AddressBlock'
import PhoneBlock from './PhoneBlock'
import UnitBlock from './UnitBlock'

import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate, updatedDateToReqServer } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'
import {
  checkAvailableDataProps,
  SettingsProps,
  CallTypeProps,
  AddressProps,
  AppointmentProps,
  ClientProps,
  JobProps,
  PhonesProps,
  UnitProps,
  EditProps,
  SelectedAvalTime,
  PermissionsProps
} from './Models'

import '../../styles/pages/AppointmentSchedule.sass'

interface HttpClientCallTypeReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallTypeProps
}

interface HttpClientZipReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: checkAvailableDataProps
}

interface HttpReserveReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: string
}

interface HttpAppointDataReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AppointDataProps
}

export interface AppointDataProps {
  addresses: AddressProps[]
  appointment: AppointmentProps
  client: ClientProps
  edit: EditProps
  job: JobProps
  phones: PhonesProps[]
  units: UnitProps[]
  permissions: PermissionsProps
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [isSending, setIsSending] = useState(false)

  const [settings, setSettings] = useState<SettingsProps>({
    showDays: 3,
    show_service: true,
    availability: true,
    area: false,
    duration: '30 min',
    on_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
    on_time_start: '',
    on_time_end: ''
  })
  const [isManualAval, setIsManualAval] = useState(false)
  const [checkAvailable, setCheckAvailable] = useState<checkAvailableDataProps>({
    available: [],
    status: []
  })
  const [doubleSelected, setDoubleSelected] = useState('')

  const [selectedAvalTime, setSelectedAvalTime] = useState<SelectedAvalTime | null>(null)
  const [selectedMenualTime, setSelectedMenualTime] = useState(false)

  const [appointmentSearch, setAppointmentSearch] = useState('')
  const [selectedAppointment, setSelectedAppointment] = useState<string>('')
  const [appointmentSearchData, setAppointmentSearchData] = useState<CallTypeProps | null>(null)
  const [status, setStatus] = useState<{ available: boolean, text: string }[] | []>([])
  const [outAvailable, setOutAvailable] = useState('')
  const [zipCheck, setZipCheck] = useState('')

  const [editButton, setEditButton] = useState(false)

  const [editing, setEditing] = useState('')

  const [appointmentReport, setAppointmentReport] = useState<Partial<AppointDataProps>>({})

  const [clientData, setClientData] = useState<ClientProps>({
    client_id: '',
    company_name: '',
    first_name: '',
    last_name: '',
    jobs_count: 0
  })

  const [jobData, setJobData] = useState({
    job_id: '',
    name: '',
    source: '',
    source_id: '',
    status: '',
    status_id: '',
    appointments_count: 0,
    company_id: '',
    company: '',
  })

  const [appointmentData, setAppointmentData] = useState({
    appointment_finish: '',
    appointment_id: '',
    appointment_start: '',
    is_sent: 0,
    name: '',
    non_schedule: 0,
    note: '',
    service_resource: '',
    service_resource_code: 0,
    service_resource_id: '',
    service_resource_time_zone: '',
    status: '',
    status_id: 0,
    type: '',
  })

  const [addressData, setAddressData] = useState<AddressProps[] | []>([])

  const [phoneData, setPhoneData] = useState<PhonesProps[] | []>([])

  const [unitsData, setUnitsData] = useState<UnitProps[] | []>([])

  const [startReserve, setStartReserve] = useState(false)
  const [cancelChangeTime, setCancelChangeTime] = useState(false)

  const [appointmentPopup, setAppointmentPopup] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)
  const [readyToChangeManual, setReadyToChangeManual] = useState(false)

  const [readyToSendUpdate, setReadyToSendUpdate] = useState(false)
  const [sendUpdatePopup, setSendUpdatePopup] = useState(false)
  const [isSend, setIsSend] = useState(false)

  const [loading, setLoading] = useState(false)

  const [restoreAppoinment, setRestoreAppoinment] = useState(false)

  const [menualSettings, setMenualSettings] = useState({
    start: '',
    end: '',
    service_resource_id: '',
    service_resource_name: '',
    time_zone: '',
  })

  //  search input in callType component
  async function handleSearchOnCallType(search?: string) {
    cancelReserve()
    setLoading(true)
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: searchData, success, error } } = await httpClientUpdate.get('/bookings/client-search', {
        params: {
          account_id: activeAccountId,
          key_words: search ? search : appointmentSearch,
          page: 1,
          limit_rows: 100,
        }
      }) as { data: HttpClientCallTypeReport }
      if (success) {
        setEditing('')
        setSelectedAppointment('')
        setAppointmentSearchData(searchData)
        setEditButton(false)
        setRestoreAppoinment(false)
        setSelectedMenualTime(false)
        setMenualSettings({
          start: '',
          end: '',
          service_resource_id: '',
          service_resource_name: '',
          time_zone: '',
        })
        // setZipCheck('')
        setStartReserve(false)
        setLoading(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
        setLoading(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
      setLoading(false)
    }
  }

  function getTypeAppointment(type: string) {
    let typeString = ''
    if (type === 'Service Call') {
      typeString = 'SC'
    } else if (type === 'Follow Up') {
      typeString = 'FU'
    } else if (type === 'Recall') {
      typeString = 'RC'
    } else {
      typeString = type
    }
    return typeString
  }

  // get appointment data
  async function getEditAppointment(): Promise<void> {
    cancelReserve()
    setLoading(true)
    try {
      // https://2022back4.artemiudintsev.com/api/appointments/{APPOINTMENT_ID}/reschedule?account_id=88888?
      const { data: { data: appointData, success, error } } = await httpClientUpdate.get(`/appointments/${selectedAppointment}/reschedule`, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpAppointDataReport }
      if (success && appointData.permissions.appointment_reschedule_show) {
        setEditing('')
        // setZipCheck('')
        setRestoreAppoinment(false)
        setMenualSettings({
          start: '',
          end: '',
          service_resource_id: '',
          service_resource_name: '',
          time_zone: '',
        })
        setSelectedMenualTime(false)
        setAppointmentReport({
          ...appointData,
          addresses: appointData.addresses.map(address => {
            let apt = ''
            let unit = ''

            if (address.unit) {
              if (address.unit.includes('#')) {
                let split = address.unit.split('#')

                unit = split[1]
                apt = split[0]
              } else {
                unit = address.unit
                apt = 'Apt'
              }
            } else {
              apt = 'Apt'
            }

            return {
              ...address,
              apt,
              unit
            }
          })
        })
        setStartReserve(false)

        setIsSend(!!appointData.appointment.is_sent)

        let updatedArr: CallTypeProps = { jobs: [] }
        appointmentSearchData && appointmentSearchData.jobs.forEach(item => {
          let appoint = item.appointments.filter((app, index) => selectedAppointment === app.appointment_id)
          let upadtedAddress = appointData.addresses.filter(address => !!address.is_appointment)
          if (appoint.length) {
            let updatedAppoint = { ...item }
            updatedAppoint.appointments = [{
              address: upadtedAddress.length ? `${upadtedAddress[0].street}, ${upadtedAddress[0].unit} ${upadtedAddress[0].city}, ${upadtedAddress[0].state} ${upadtedAddress[0].postal_code}` : item.appointments[0].address,
              appointment_id: appointData.appointment.appointment_id,
              appointment_end: appointData.appointment.appointment_finish,
              appointment_name: appointData.appointment.name,
              appointment_start: appointData.appointment.appointment_start,
              appointment_type: appointData.appointment.type,
              service_resource_code: appointData.appointment.service_resource_code,
              service_resource_name: appointData.appointment.service_resource,
              service_resource_time_zone: appointData.appointment.service_resource_time_zone,
              status: appointData.appointment.status,
              status_id: appointData.appointment.status_id,
              note: appointData.appointment.note,
              non_schedule: appointData.appointment.non_schedule,
              units: appoint[0].units
            }]
            updatedAppoint.units = appointData.units
            updatedAppoint.contact_phones = appointData.phones.map(item => {
              return {
                name: item.name,
                phone: item.phone
              }
            })
            updatedArr.jobs.push(updatedAppoint)
            return updatedAppoint
          } else {
            return null
          }
        })

        setAppointmentSearchData({
          ...appointmentSearchData,
          jobs: updatedArr.jobs
        })

        setClientData(appointData.client)
        setJobData(appointData.job)
        setAppointmentData({
          ...appointData.appointment,
          type: getTypeAppointment(appointData.appointment.type)
        })
        setAddressData(appointData.addresses.map(item => {
          let apt = ''
          let unit = ''

          if (item.unit) {
            if (item.unit.includes('#')) {
              let split = item.unit.split('#')

              unit = split[1]
              apt = split[0]
            } else {
              unit = item.unit
              apt = 'Apt'
            }
          } else {
            apt = 'Apt'
          }

          return {
            ...item,
            unit,
            apt,
            use: !!item.is_appointment,
            new: false
          }
        }))
        setPhoneData(appointData.phones.map(item => {
          return {
            ...item,
            use: !!item.is_appointment
          }
        }))
        setUnitsData(appointData.units.map(item => {
          return {
            ...item,
            is_new: false,
            is_use: !!item.is_appointment
          }
        }))

        setEditButton(true)
        setLoading(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setLoading(false)
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
      setLoading(false)
    }
  }

  // loading check-available zip
  async function checkZip(zip?: string) {
    try {
      const { data: { data: checkAvailableData, success, error } } = await httpClientUpdate.get('/bookings/check-available', {
        params: {
          account_id: activeAccountId,
          zip_code: zip ? zip : zipCheck,
          days: settings.showDays,
          date: moment(settings.on_date).format('YYYY-MM-DD')
        }
      }) as { data: HttpClientZipReport }
      if (success) {
        if (checkAvailableData.status.length) {
          setOutAvailable('')
        } else {
          setOutAvailable('Out Of Service Area')
        }
        setCheckAvailable(checkAvailableData)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // reserve time
  async function reserveAvalTime(start: string, end: string, service_resource_id: string, type: string, time_zone: string) {
    try {
      setLoading(true)
      // https://2022back4.artemiudintsev.com/api/bookings/appointments/reserve
      const { data: { data: reserveData, success } } = await httpClientUpdate.post('/bookings/appointments/reserve', {
        account_id: activeAccountId,
        start: updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, start as string),
        end: updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, end as string),
        service_resource_id,
        type
      }) as { data: HttpReserveReport }
      if (success) {
        if (reserveData === 'Success unblock') {
          setStartReserve(false)
          setCancelChangeTime(false)
          setDoubleSelected('')
          setLoading(false)
        } else {
          setStartReserve(true)
          setLoading(false)
        }
      } else {
        setLoading(false)
        // $router.router.navigate(`${error.code}`, {
        //   reload: true
        // })
      }
    }
    catch (error) {
      setDoubleSelected('')
      setIsSend(false)
    }
  }

  // update Job Item
  async function updatedJobItem() {
    try {
      const response = await httpClientUpdate.put(`/jobs/${jobData.job_id}`, {
        account_id: activeAccountId,
        source_id: jobData.source_id ? jobData.source_id : null,
        company_id: jobData.company_id ? jobData.company_id : null
      })

      if (response.data.success) {
        setSendUpdatePopup(true)
        setEditing('')
        appointmentReport.job && setAppointmentReport({
          ...appointmentReport,
          job: {
            ...appointmentReport.job,
            source_id: jobData.source_id,
            company_id: jobData.company_id,
          }
        })
      }
    } catch (error) {

    }
  }

  // update Client Item
  async function updatedClientItem() {
    try {
      const response = await httpClientUpdate.put(`/clients/${clientData.client_id}`, {
        account_id: activeAccountId,
        firstname: clientData.first_name,
        lastname: clientData.last_name,
        company: clientData.company_name,
      })

      if (response.data.success) {
        appointmentSearchData && setAppointmentSearchData({
          ...appointmentSearchData,
          jobs: appointmentSearchData.jobs.map(item => {
            return {
              ...item,
              client_name: `${clientData.first_name} ${clientData.last_name}`
            }
          })
        })
        setAppointmentReport({
          ...appointmentReport,
          client: {
            client_id: appointmentReport.client?.client_id || '',
            jobs_count: appointmentReport.client?.jobs_count || 0,
            first_name: clientData.first_name,
            last_name: clientData.last_name,
            company_name: clientData.company_name
          }
        })
        setEditing('')
        setSendUpdatePopup(true)
      }
    } catch (error) {

    }
  }

  // update Appointment Item
  async function updatedAppointmentItem() {
    setIsSending(true)
    try {
      const response = await httpClientUpdate.put(`/appointments/${appointmentData.appointment_id}`, {
        account_id: activeAccountId,
        type: appointmentData.type ? appointmentData.type : null,
        non_schedule: appointmentData.non_schedule ? 1 : 0,
        note: appointmentData.note
      })
      if (response.data.success) {
        let updatedArr: CallTypeProps = { jobs: [] }
        appointmentSearchData && appointmentSearchData.jobs.forEach(item => {
          let updatedAppoint = { ...item }
          updatedAppoint.appointments = item.appointments.map(app => {
            if (appointmentData.appointment_id === app.appointment_id) {
              if (app.appointment_type !== appointmentData.type || app.note !== appointmentData.note) {
                setSendUpdatePopup(true)
              }
              return {
                ...app,
                appointment_type: appointmentData.type,
                non_schedule: appointmentData.non_schedule,
                note: appointmentData.note
              }
            } else {
              return { ...app }
            }
          })

          updatedArr.jobs.push(updatedAppoint)
          return updatedAppoint
        })

        appointmentReport.appointment && setAppointmentReport({
          ...appointmentReport,
          appointment: {
            ...appointmentReport.appointment,
            type: appointmentData.type,
            non_schedule: appointmentData.non_schedule,
            note: appointmentData.note
          }
        })

        setAppointmentSearchData({
          ...appointmentSearchData,
          jobs: updatedArr.jobs
        })

        setEditing('')
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // update Phones Item
  async function updatedPhonesItem() {
    setIsSending(true)
    try {
      let phones = phoneData.filter(item => item.use)
      let phonesServerData = {
        ...phones.map(item => {
          if (item.contact_phone_id) {
            return {
              contact_phone_id: item.contact_phone_id,
              name: item.name,
              phone: formatPhoneNumberToServerString(item.phone),
              is_main: item.is_main ? 1 : 0,
              is_payer: item.is_payer ? 1 : 0,
              is_sms: item.is_sms ? 1 : 0
            }
          } else {
            return {
              name: item.name,
              phone: formatPhoneNumberToServerString(item.phone),
              is_main: item.is_main ? 1 : 0,
              is_payer: item.is_payer ? 1 : 0,
              is_sms: item.is_sms ? 1 : 0
            }
          }
        })
      }
      // https://2022back4.artemiudintsev.com/api/appointments/88888212aaaaaaaaaa/reschedule/phones
      const response = await httpClientUpdate.post(`/appointments/${appointmentData.appointment_id}/reschedule/phones`, {
        account_id: activeAccountId,
        contact_id: clientData.client_id,
        phones: phonesServerData
      })

      if (response.data.success) {
        setIsSending(false)
        getEditAppointment()
        setSendUpdatePopup(true)
        setEditing('')
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // update Address Item
  async function updatedAddressItem() {
    setIsSending(true)
    try {
      let addresses = addressData.filter(item => item.use)
      // https://2022back4.artemiudintsev.com/api/appointments/88888212aaaaaaaaaa/reschedule/addresses
      const response = await httpClientUpdate.post(`/appointments/${appointmentData.appointment_id}/reschedule/addresses`, qs.stringify({
        account_id: activeAccountId,
        contact_address_id: addresses[0].address_id ? addresses[0].address_id : null,
        type: addresses[0].type,
        street: addresses[0].street,
        unit: addresses[0].unit ? `${addresses[0].apt}#${addresses[0].unit}` : '',
        city: addresses[0].city,
        state: addresses[0].state,
        postal_code: addresses[0].postal_code,
        note: addresses[0].note ? addresses[0].note : '',
      }, { skipNulls: true }))

      if (response.data.success) {
        setIsSending(false)
        getEditAppointment()
        setSendUpdatePopup(true)
        setEditing('')
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // update Units Item
  async function updatedUnitsItem() {
    setIsSending(true)
    try {
      let units = unitsData.filter(item => item.is_use)
      let unitsServerData = {
        ...units.map(item => {
          if (item.unit_id) {
            return {
              unit_id: item.unit_id,
              unit_type: item.unit_type,
              appliance_id: item.appliance_id,
              other_appliance: item.other_appliance ? item.other_appliance : null,
              brand_id: item.brand_id ? item.brand_id : null,
              other_brand: item.other_brand ? item.other_brand : null,
              model_number: item.model_number ? item.model_number : null,
              serial_number: item.serial_number ? item.serial_number : null,
              description: item.description ? item.description : null,
              price: item.price ? item.price : null
            }
          } else {
            return {
              unit_type: item.unit_type,
              appliance_id: item.appliance_id,
              other_appliance: item.other_appliance ? item.other_appliance : null,
              brand_id: item.brand_id ? item.brand_id : null,
              other_brand: item.other_brand ? item.other_brand : null,
              model_number: item.model_number ? item.model_number : null,
              serial_number: item.serial_number ? item.serial_number : null,
              description: item.description ? item.description : null,
              price: item.price ? item.price : null
            }
          }
        })
      }
      // https://2022back4.artemiudintsev.com/api/appointments/88888212aaaaaaaaaa/reschedule/units
      const response = await httpClientUpdate.post(`/appointments/${appointmentData.appointment_id}/reschedule/units`, qs.stringify({
        account_id: activeAccountId,
        units: unitsServerData
      }, { skipNulls: true }))

      if (response.data.success) {
        getEditAppointment()
        setSendUpdatePopup(true)
        setEditing('')
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // update changed time
  async function updatedChangeTime() {
    setIsSending(true)
    try {
      let appointment_start: DateTime | "" = ''
      let appointment_finish: DateTime | "" = ''
      let service_resource_id = ''
      checkAvailable.available.forEach((item, idx) => {
        item.service_resource.forEach((service, index) => {
          if (doubleSelected === `${service.service_resource_id}_${idx}_${index}`) {
            service_resource_id = service.service_resource_id
            appointment_start = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', service.service_resource_time_zone, `${item.date.replaceAll('/', '-')} ${service.time_slot_start}` as string)
            appointment_finish = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', service.service_resource_time_zone, `${item.date.replaceAll('/', '-')} ${service.time_slot_finish}` as string)
          }
        })
      })

      // https://2022back4.artemiudintsev.com/api/bookings/appointments/88888212yz4xmcqvew
      const response = await httpClientUpdate.put(`/bookings/appointments/${appointmentData.appointment_id}`, {
        account_id: activeAccountId,
        service_resource_id: service_resource_id,
        appointment_start: appointment_start,
        appointment_finish: appointment_finish
      })

      if (response.data.success) {
        getEditAppointment()
        setDoubleSelected('')
        setStartReserve(false)
        setCheckAvailable({
          available: [],
          status: []
        })
        setCancelChangeTime(true)
        setZipCheck('')
        setEditing('')
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  async function updatedChangeMenualTime() {
    setIsSending(true)
    try {
      let time_zone = ''
      appointmentReport?.edit?.service_resources.forEach(item => {
        if (item.service_resource_id === menualSettings.service_resource_id) {
          time_zone = item.time_zone
        }
      })
      let start = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.start}` as string)
      let end = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.end}` as string)


      // https://2022back4.artemiudintsev.com/api/bookings/appointments/88888212yz4xmcqvew
      const response = await httpClientUpdate.put(`/bookings/appointments/${appointmentData.appointment_id}`, {
        account_id: activeAccountId,
        service_resource_id: menualSettings.service_resource_id,
        appointment_start: start,
        appointment_finish: end,
        is_manual: 1
      })

      if (response.data.success) {
        getEditAppointment()
        setEditing('')
        setMenualSettings({
          start: '',
          end: '',
          service_resource_id: '',
          service_resource_name: '',
          time_zone: '',
        })
        setSelectedMenualTime(false)
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // save restore appointment
  async function handleSaveRestoreAppointment() {
    setIsSending(true)
    try {
      let appointment_start: DateTime | "" = ''
      let appointment_finish: DateTime | "" = ''
      let service_resource_id = ''
      checkAvailable.available.forEach((item, idx) => {
        item.service_resource.forEach((service, index) => {
          if (doubleSelected === `${service.service_resource_id}_${idx}_${index}`) {
            service_resource_id = service.service_resource_id
            appointment_start = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', service.service_resource_time_zone, `${item.date.replaceAll('/', '-')} ${service.time_slot_start}` as string)
            appointment_finish = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', service.service_resource_time_zone, `${item.date.replaceAll('/', '-')} ${service.time_slot_finish}` as string)
          }
        })
      })
      // https://2022back4.artemiudintsev.com/api/bookings/appointments/recovery
      const response = await httpClientUpdate.post(`/bookings/appointments/recovery`, {
        account_id: activeAccountId,
        appointment_id: appointmentData.appointment_id,
        service_resource_id: service_resource_id,
        start: appointment_start,
        end: appointment_finish
      })

      if (response.data.success) {
        setRestoreAppoinment(false)
        setDoubleSelected('')
        setStartReserve(false)
        setCheckAvailable({
          available: [],
          status: []
        })
        setCancelChangeTime(true)
        setZipCheck('')
        setEditing('')
        getEditAppointment()
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // save restore appointment
  async function handleSaveMenualRestoreAppointment() {
    setIsSending(true)
    try {
      let time_zone = ''
      appointmentReport?.edit?.service_resources.forEach(item => {
        if (item.service_resource_id === menualSettings.service_resource_id) {
          time_zone = item.time_zone
        }
      })

      let start = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.start}` as string)
      let end = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, `${moment(settings.on_date).format('YYYY-MM-DD')} ${menualSettings.end}` as string)

      // https://2022back4.artemiudintsev.com/api/bookings/appointments/recovery
      const response = await httpClientUpdate.post(`/bookings/appointments/recovery`, {
        account_id: activeAccountId,
        appointment_id: appointmentData.appointment_id,
        service_resource_id: menualSettings.service_resource_id,
        start: start,
        end: end,
        is_manual: 1
      })

      if (response.data.success) {
        setRestoreAppoinment(false)
        setMenualSettings({
          start: '',
          end: '',
          service_resource_id: '',
          service_resource_name: '',
          time_zone: '',
        })
        setSelectedMenualTime(false)
        setZipCheck('')
        setEditing('')
        getEditAppointment()
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  function handleChangeTime() {
    let addresses = addressData.filter(item => item.use)
    if (!!addresses.length) {
      setZipCheck(addresses[0].postal_code)
      checkZip(addresses[0].postal_code)
    }
  }

  function handleCancelChangeTime() {
    setZipCheck('')
    setDoubleSelected('')
    setCheckAvailable({
      available: [],
      status: []
    })
    setCancelChangeTime(true)
    setStartReserve(false)
    setReadyToSendUpdate(false)
  }

  async function handleConfirmCancelAppointment() {
    setIsSending(true)
    try {
      // https://2022back4.artemiudintsev.com/api/bookings/cancel-appointment
      const response = await httpClientUpdate.post(`/bookings/cancel-appointment`, qs.stringify({
        account_id: activeAccountId,
        appointment_id: appointmentData.appointment_id
      }))
      if (response.data.success) {
        setReadyToDelete(false)
        setAppointmentPopup(false)
        getEditAppointment()
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  // send appointment
  async function handleSend() {
    setIsSending(true)
    try {
      // https://2022back4.artemiudintsev.com/api/notifications/serviceresource/appointment
      const response = await httpClientUpdate.post(`/notifications/serviceresource/appointment`, {
        account_id: activeAccountId,
        appointment_id: appointmentData.appointment_id,
        is_update: 1
      })

      if (response.data.success) {
        setReadyToSendUpdate(false)
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setIsSending(false)
    }
  }

  useEffect(() => {
    updated && resetPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  useEffect(() => {
    setStatus(getStatusZip())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAvailable])

  useEffect(() => {
    if ($router.router.getState().params.caller_phone) {
      setAppointmentSearch($router.router.getState().params.caller_phone)
      handleSearchOnCallType($router.router.getState().params.caller_phone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router])

  useEffect(() => {
    if (doubleSelected) {
      let appointment_start: DateTime | "" = ''
      let appointment_finish: DateTime | "" = ''
      let service_resource_time_zone = ''
      let service_resource_id = ''
      let service_resource_name = ''
      checkAvailable.available.forEach((item, idx) => {
        item.service_resource.forEach((service, index) => {
          if (doubleSelected === `${service.service_resource_id}_${idx}_${index}`) {
            service_resource_time_zone = service.service_resource_time_zone
            service_resource_id = service.service_resource_id
            service_resource_name = service.service_resource_code ? `${service.service_resource}(${service.service_resource_code})` : service.service_resource
            appointment_start = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', service.service_resource_time_zone, `${item.date.replaceAll('/', '-')} ${service.time_slot_start}` as string)
            appointment_finish = updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', service.service_resource_time_zone, `${item.date.replaceAll('/', '-')} ${service.time_slot_finish}` as string)
          }
        })
      })
      setSelectedAvalTime({
        service_resource_time_zone,
        time_slot_finish: appointment_finish,
        time_slot_start: appointment_start,
        service_resource_id,
        service_resource_name
      })
    } else {
      setSelectedAvalTime(null)
    }
  }, [doubleSelected, checkAvailable, appointmentData.service_resource_time_zone])

  useEffect(() => {
    setMenualSettings({
      start: '',
      end: '',
      service_resource_id: '',
      service_resource_name: '',
      time_zone: '',
    })
    setSelectedMenualTime(false)
    setRestoreAppoinment(false)
    setSettings({
      ...settings,
      availability: true,
    })
    setCheckAvailable({
      available: [],
      status: []
    })
    doubleSelected && handleCancelChangeTime()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment, settings.on_date])

  function cancelReserve() {
    if (startReserve) {
      setCheckAvailable({
        available: [],
        status: []
      })
      setSelectedAvalTime(null)
      setStatus([])

      doubleSelected && setCancelChangeTime(true)
    }
  }

  function handleCancelRestore() {
    setRestoreAppoinment(false)
    cancelReserve()
  }

  function getStatusZip() {
    let status: { available: boolean; text: string }[] = []

    if (checkAvailable) {
      let statusArr: { available: boolean, text: string }[] = []
      checkAvailable.status.forEach(item => {
        if (statusArr.length === 0) {
          statusArr.push({
            available: item.available,
            text: item.service_resource_code ? `${item.service_resource} (${item.service_resource_code})` : `${item.service_resource}`
          })
        } else {
          let checkStatus = statusArr.filter(str => str.text.includes(item.service_resource))
          if (checkStatus.length === 0) {
            statusArr.push({
              available: item.available,
              text: item.service_resource_code ? `${item.service_resource} (${item.service_resource_code})` : `${item.service_resource}`
            })
          }
        }
      })

      if (statusArr.length) {
        status = statusArr
      }
    }
    return status
  }

  function changeZipCode(value: string) {
    setZipCheck(value)
  }

  function resetPage() {
    setOutAvailable('')
    setMenualSettings({
      start: '',
      end: '',
      service_resource_id: '',
      service_resource_name: '',
      time_zone: '',
    })
    setSelectedMenualTime(false)
    setRestoreAppoinment(false)
    setSettings({
      showDays: 3,
      show_service: true,
      availability: true,
      area: false,
      duration: '30 min',
      on_date: DateTime.now().setZone(timeZone).startOf('day').toJSDate(),
      on_time_start: '',
      on_time_end: ''
    })
    setCheckAvailable({
      available: [],
      status: []
    })
    setDoubleSelected('')
    setAppointmentSearch('')
    setSelectedAppointment('')
    setAppointmentSearchData(null)
    setStatus([])
    setZipCheck('')
    setEditButton(false)
    setEditing('')
    setAppointmentReport({})
    setClientData({
      client_id: '',
      company_name: '',
      first_name: '',
      last_name: '',
      jobs_count: 0
    })
    setJobData({
      job_id: '',
      name: '',
      source: '',
      source_id: '',
      status: '',
      status_id: '',
      appointments_count: 0,
      company_id: '',
      company: '',
    })
    setAppointmentData({
      appointment_finish: '',
      appointment_id: '',
      appointment_start: '',
      is_sent: 0,
      name: '',
      non_schedule: 0,
      note: '',
      service_resource: '',
      service_resource_code: 0,
      service_resource_id: '',
      service_resource_time_zone: '',
      status: '',
      status_id: 0,
      type: '',
    })
    setAddressData([])
    setPhoneData([])
    setUnitsData([])
    setStartReserve(false)
    setIsSending(false)
  }

  function checkChangeTime() {
    let check = false

    let useAddress = appointmentReport?.addresses ? appointmentReport.addresses.filter(item => item.is_appointment) : []

    if (useAddress.length) {
      useAddress.forEach(item => {
        if (item.postal_code.length < 4) {
          check = true
        }
      })
    } else {
      check = true
    }
    return check
  }

  function handleManualSchedule(availability: boolean) {
    if (availability) {
      setSettings({ ...settings, availability: availability })
      setIsManualAval(false)
      setReadyToChangeManual(false)
      setMenualSettings({
        start: '',
        end: '',
        service_resource_id: '',
        service_resource_name: '',
        time_zone: '',
      })
      setSelectedMenualTime(false)
    } else {
      setIsManualAval(true)
    }
  }

  function handleManualAvalTime() {
    setIsManualAval(false)
    setSettings({ ...settings, availability: false })
    setReadyToChangeManual(false)
    doubleSelected && handleCancelChangeTime()
    setCheckAvailable({
      available: [],
      status: []
    })
    setZipCheck('')
    setStatus([])
  }

  function handleCancelChangeMenualTime() {
    setMenualSettings({
      start: '',
      end: '',
      service_resource_id: '',
      service_resource_name: '',
      time_zone: '',
    })
    setSelectedMenualTime(false)
  }

  return (
    <div className='appointment_schedule_page'>
      <div className='center AppointmentsPage_Item entity-edit'>
        <SearchJob
          appointmentSearch={appointmentSearch}
          setAppointmentSearch={setAppointmentSearch}
          selectedAppointment={selectedAppointment}
          handleSearchAppointment={handleSearchOnCallType}
          appointmentSearchData={appointmentSearchData}
          setSelectedAppointment={setSelectedAppointment}
          handleEditButton={getEditAppointment}
          loading={loading}
        />

        {
          editButton &&
          <ClientBlock
            disabledEditButton={editing !== ''}
            editing={editing === 'Client'}
            setEditing={setEditing}
            clientData={clientData}
            setClientData={setClientData}
            appointmentReport={appointmentReport}
            updatedClientItem={updatedClientItem}
            permissions_client_edit={appointmentReport.permissions?.client_edit || false}
          />
        }

        {
          editButton &&
          <JobBlock
            disabledEditButton={editing !== ''}
            editing={editing === 'Job'}
            setEditing={setEditing}
            data={jobData}
            setData={setJobData}
            appointmentReport={appointmentReport}
            updatedJobItem={updatedJobItem}
            permissions_job_edit={appointmentReport.permissions?.job_edit || false}
          />
        }

        {
          editButton &&
          <AppointmentBlock
            disabledEditButton={editing !== ''}
            editing={editing === 'Appointment'}
            setEditing={setEditing}
            data={appointmentData}
            setData={setAppointmentData}
            appointmentReport={appointmentReport}
            handleChangeTime={handleChangeTime}
            doubleSelected={doubleSelected}
            handleCancelChangeTime={handleCancelChangeTime}
            setAppointmentPopup={setAppointmentPopup}
            updatedAppointmentItem={updatedAppointmentItem}
            updatedChangeTime={updatedChangeTime}
            status={appointmentData.status === 'Canceled'}
            setRestoreAppoinment={setRestoreAppoinment}
            restoreAppoinment={restoreAppoinment}
            handleSaveRestoreAppointment={handleSaveRestoreAppointment}
            selectedAvalTime={selectedAvalTime}
            checkChangeTime={checkChangeTime()}
            handleCancelRestore={handleCancelRestore}
            settings={settings}
            selectedMenualTime={selectedMenualTime}
            handleCancelChangeMenualTime={handleCancelChangeMenualTime}
            updatedChangeMenualTime={updatedChangeMenualTime}
            menualSettings={menualSettings}
            handleSaveMenualRestoreAppointment={handleSaveMenualRestoreAppointment}
            readyToSendUpdate={readyToSendUpdate}
            handleSend={handleSend}
            isSend={isSend}
            permmisions_appointment_restore={appointmentReport.permissions?.appointment_reschedule_appointment_restore || false}
            permmisions_appointment_change_time={appointmentReport.permissions?.appointment_reschedule_appointment_change_time || false}
            permmisions_appointment_appointment_cancel={appointmentReport.permissions?.appointment_reschedule_appointment_cancel || false}
            permmisions_appointment_edit={appointmentReport.permissions?.appointment_edit || false}
            isSending={isSending}
          />
        }

        {
          editButton &&
          <AddressBlock
            disabledEditButton={editing !== ''}
            editing={editing === 'Address'}
            setEditing={setEditing}
            data={addressData}
            setData={setAddressData}
            appointmentReport={appointmentReport}
            updatedAddressItem={updatedAddressItem}
            status={appointmentData.status === 'Canceled'}
            permmisions_address_edit={appointmentReport.permissions?.appointment_reschedule_address_edit || false}
            isSending={isSending}
          />
        }

        {
          editButton &&
          <PhoneBlock
            disabledEditButton={editing !== ''}
            editing={editing === 'Phones'}
            setEditing={setEditing}
            data={phoneData}
            setData={setPhoneData}
            appointmentReport={appointmentReport}
            updatedPhonesItem={updatedPhonesItem}
            status={appointmentData.status === 'Canceled'}
            permmisions_phones_edit={appointmentReport.permissions?.appointment_reschedule_phones_edit || false}
            isSending={isSending}
          />
        }

        {
          editButton &&
          <UnitBlock
            disabledEditButton={editing !== ''}
            editing={editing === 'Units'}
            setEditing={setEditing}
            data={unitsData}
            setData={setUnitsData}
            appointmentReport={appointmentReport}
            updatedUnitsItem={updatedUnitsItem}
            status={appointmentData.status === 'Canceled'}
            permmisions_units_edit={appointmentReport.permissions?.appointment_reschedule_units_edit || false}
            isSending={isSending}
          />
        }

        {/* Item share popup */}
        {appointmentPopup ? (
          <div className="item-delete-popup" onClick={() => setAppointmentPopup(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Cancel Appointment
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that this action cancels the appointment"
                  value={readyToDelete}
                  onChange={(value) => setReadyToDelete(value)}
                />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => {
                    setReadyToDelete(false)
                    setAppointmentPopup(false)
                  }}
                >
                  Close
                </button>

                <button
                  disabled={!readyToDelete || isSending}
                  className="_bordered _red"
                  onClick={() => handleConfirmCancelAppointment()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {
          !!isSend &&
            sendUpdatePopup ? (
            <div className="item-delete-popup" onClick={() => {
              setSendUpdatePopup(false)
              setReadyToSendUpdate(true)
            }}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Send Update
                </div>

                <div className="checkbox-wrapper">
                  <p>You need to send an update to the service resource after all the changes</p>
                  {/* <Checkbox contents="I understand that this action cancels the appointment"
                  value={readyToDelete}
                  onChange={(value) => setReadyToDelete(value)}
                /> */}
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => {
                      setSendUpdatePopup(false)
                      setReadyToSendUpdate(true)
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          ) : null}

        {/* Item share popup */}
        {isManualAval ? (
          <div
            className="item-delete-popup"
            onClick={() => {
              setIsManualAval(false)
              setReadyToChangeManual(false)
            }}
          >
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Manual schedule
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that I set the appointment manually without checking for available time slots"
                  value={readyToChangeManual}
                  onChange={(value) => setReadyToChangeManual(value)}
                />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => {
                    setReadyToChangeManual(false)
                    setIsManualAval(false)
                  }}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToChangeManual}
                  className="_bordered _red"
                  onClick={() => handleManualAvalTime()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        ) : null}

      </div>

      <div className='right_side'>
        <AppointmentSettings
          settings={settings}
          setSettings={setSettings}
          handleManualSchedule={handleManualSchedule}
          permissions_schedule_manual={appointmentReport.permissions?.appointment_schedule_manual || false}
        />

        <ZipCode
          status={status}
          checkZip={checkZip}
          changeZipCode={changeZipCode}
          zipCheck={zipCheck}
          availability={settings.availability}
          outAvailable={outAvailable}
        />

        <AvalTime
          checkAvailable={checkAvailable}
          show_service={settings.show_service}
          doubleSelected={doubleSelected}
          setDoubleSelected={setDoubleSelected}
          reserveAvalTime={reserveAvalTime}
          startReserve={startReserve}
          cancelChangeTime={cancelChangeTime}
          permissionZipCheck={selectedAppointment !== '' && editButton}
          settings={settings}
          selectedMenualTime={selectedMenualTime}
          setSelectedMenualTime={setSelectedMenualTime}
          menualSettings={menualSettings}
          setMenualSettings={setMenualSettings}
          edit={appointmentReport?.edit}
          permissions_reserve_time={appointmentReport.permissions?.appointment_reserve_time || false}
          loading={loading}
        />
      </div>
    </div>
  )
}
